import React, { useEffect, useState, Suspense, lazy, useContext } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import Base from "./base.js";
import { trackPromise } from "react-promise-tracker";
import GuService from "./services/GuService";
import crm from "./services/crm";
import Swal from "sweetalert2";
import OneSignalReact from "react-onesignal";
import { ContextAcompanhamentoHistoricoProvider } from "./contexts/AcompanhamentoHistoricoContext";
import { ReguaNutricaoProvider } from "./contexts/ReguaNutricaoContext";
import DommusLoading from './components/DommusLoading/DommusLoading';
import { AlertSemPemissaoPagina } from "./components/AlertSemPermissaoPagina/index";
import ConfiguracoesGestaoService from "./services/ConfiguracoesGestaoService.js";
import { ContextAcompanhamentoReguaNutricaoProvider } from "./contexts/AcompanhamentoReguaNutricaoContext";
import { UsuarioContext } from "./contexts/UsuarioContext";

export default function Routes({}) {
  const Oportunidades = lazy(() => import('./pages/Oportunidades/'));
  const Logout = lazy(() => import("./pages/Logout"));
  const DetalheOportunidade = lazy(() => import("./pages/DetalheOportunidade/"));
  const Campanhas = lazy(() => import("./pages/Campanhas"));
  const PerfisUsuarios = lazy(() => import("./pages/PerfisUsuarios"));
  const Dashboards = lazy(() => import("./pages/Dashboards/index.tsx"));
  const NewDashboard = lazy(() => import("./pages/NewDashboard"));
  const LeadsNaoDistribuidos = lazy(() => import("./pages/Leads/NaoDistribuidos"));
  const FilasAtendimento = lazy(() => import("./pages/FilasAtendimento"));
  const Integracoes = lazy(() => import("./pages/Integracoes"));
  const Relatorios = lazy(() => import("./pages/Relatorios"));
  const Importacao = lazy(() => import("./pages/Importacao"));
  const OfertaAtiva = lazy(() => import("./pages/OfertaAtiva"));
  const AgendamentoVisita = lazy(() => import("./pages/AgendamentoVisita"));
  const VisitasRecepcionista = lazy(() => import("./pages/Recepcionista/Visitas"));
  const SolicitacoesClonagem = lazy(() => import("./pages/SolicitacoesClonagem"));
  const PortalCorretor = lazy(() => import("./pages/PortalCorretor"));
  const PerfilEmpreendimento = lazy(() => import("./pages/PortalCorretor/perfil"));
  const OfertaAtivaAcessar = lazy(() => import("./pages/OfertaAtiva/OfertaAtivaAcessar"));
  const GuLogin = lazy(() => import("./pages/Auth/GuLogin"));
  const Midia = lazy(() => import("./pages/Configuracoes/Midia"));
  const MotivosDesistencia = lazy(() => import("./pages/Configuracoes/MotivosDesistencias"));
  const Temperatura = lazy(() => import("./pages/Configuracoes/Temperatura"));
  const TipoHistoricoLead = lazy(() => import("./pages/Configuracoes/TipoHistoricoLead/"));
  const TipoPendencia = lazy(() => import("./pages/Configuracoes/TipoPendencia"));
  const Origem = lazy(() => import("./pages/Configuracoes/Origem"));
  const MapeamentoMidias = lazy(() => import("./pages/Configuracoes/Mapeamento/midias"));
  const MapeamentoOrigem = lazy(() => import("./pages/Configuracoes/Mapeamento/origem"));
  const MapeamentoCampanhas = lazy(() => import("./pages/Configuracoes/Mapeamento/campanhas"));
  const MapeamentoEmpreendimentos = lazy(() => import("./pages/Configuracoes/Mapeamento/empreendimentos"));
  const MapeamentoImovelWeb = lazy(() => import("./pages/Configuracoes/Mapeamento/ImovelWeb"));
  const MapeamentoEmail = lazy(() => import("./pages/Configuracoes/Mapeamento/email"));
  const ConfiguracoesGestao = lazy(() => import("./pages/Configuracoes/configuracoes-gestao"));
  const PerfilUsuario = lazy(() => import("./pages/PerfilUsuario"));
  const CamposQualificacao = lazy(() => import("./pages/CamposQualificacao"));
  const ReguaNutricao = lazy(() => import("./pages/ReguaNutricao"));
  const Atualizacoes = lazy(() => import("./pages/Atualizacoes"));
  const TempoOnline = lazy(() => import("./pages/TempoOnline"));
  const LocaisAtendimentos = lazy(() => import("./pages/Configuracoes/LocaisAtendimento"));
  const FunilDeVendas = lazy(() => import("./pages/Configuracoes/FunilDeVendas"));
  const GestaoDeEtapasDeStatus  = lazy(() => import("./pages/Configuracoes/GestaoDeEtapasDeStatus"));
  const AcompanhamentoHistorico = lazy(() => import("./pages/AcompanhamentoHistorico"));
  const HistoricoDistribuicao  = lazy(() => import("./pages/HistoricoDistribuicao"));
  const RotinasDoSistema = lazy(() => import("./pages/RotinasDoSistema"));
  const Automacoes = lazy(() => import("./pages/Configuracoes/Automacoes"));
  const Questionario = lazy(() => import("./pages/Configuracoes/Questionario"));
  const permissaoMarcarConcluidoVisita = GuService.getLocalState('permissaoMarcarConcluido');
  const permissaoMarcarConcluidoTarefa = GuService.getLocalState('permissaoMarcarConcluidoTarefa');
  const AcompanhamentoReguaNutricao = lazy(() => import("./pages/AcompanhamentoReguaNutricao"));
  const CentralRecepcaoLeads = lazy(() => import("./pages/CentralRecepcaoLeads"));
  const InvestimentoMidias = lazy(() => import("./pages/InvestimentoMidias"));
  const RelatoriosDiversos = lazy(() => import("./pages/Relatorios/Diversos"));
  

  const [tokenValido, setTokenValido] = useState(false);
  const history = useHistory();
  const { setPermissoes, validarPermissaoAcessoTela, permissoes } = useContext(UsuarioContext)

  useEffect(() => {
    if (!history.location.pathname.includes("gu-login")) {
      trackPromise(valida());
    }

      OneSignalReact.init({
        appId: '9b579eef-818f-441f-802c-9c88ae723544',
      })
      OneSignalReact.getUserId(function(userId) {
        let device = null
        if(userId != null) {
          if( navigator.userAgent.match(/Android/i)
          || navigator.userAgent.match(/webOS/i)
          || navigator.userAgent.match(/iPhone/i)
          || navigator.userAgent.match(/iPad/i)
          || navigator.userAgent.match(/iPod/i)
          || navigator.userAgent.match(/BlackBerry/i)
          || navigator.userAgent.match(/Windows Phone/i)
          ){
            device = 'mobile'
          }
          else {
            device = 'desktop'
          }
          trackPromise(
            crm.put(`perfil-usuario/alterar-ids-onesignal/${userId}/${device}`)
          ).catch((error) => {
            Swal.fire({
                titleText: "Ooops...",
                text: "Ocorreu um erro ao atualizar os dados do usuário: " + error,
                icon: 'error'
            });
          })
        }
      })
  }, []);

  async function valida() {
    let tokenAtual = GuService.getLocalState("token");
    if (
      GuService.getLocalState("token") != null &&
      GuService.getLocalState("idUsuario") != null
    ) {
      GuService.validaToken(tokenAtual)
        .then((res) => {
          setPermissoes(res.data.permissoes);
          setTokenValido(res.data.valido);
        })
        .catch((err) => {
          GuService.redirectGU()
        });
    } else {
      GuService.redirectGU();
    }

    if ( JSON.parse(permissaoMarcarConcluidoVisita) != true && JSON.parse(permissaoMarcarConcluidoVisita) != false ) {
      ConfiguracoesGestaoService.buscarConfiguracao("permissoes_acoes_agendamento_visitas").then((response) => {
          if ( response.data != "" ) {
              let permissoes = response.data.checkbox;

              GuService.setLocalState('permissaoMarcarConcluido', permissoes && 
              permissoes.includes(Number(GuService.getLocalState("tipoUsuario"))))
          }
      })
    }

    if ( JSON.parse(permissaoMarcarConcluidoTarefa) != true && JSON.parse(permissaoMarcarConcluidoTarefa) != false ) {
      ConfiguracoesGestaoService.buscarConfiguracao("concluir_tarefa").then((response) => {
          if ( response.data != "" ) {
              let permissoes = response.data;

              GuService.setLocalState('permissaoMarcarConcluidoTarefa', permissoes && 
              permissoes.includes(Number(GuService.getLocalState("tipoUsuario"))))
          }
      })
    }
  }

  return (
    <Switch>
      <Suspense fallback={<DommusLoading />}>
        <Route path="/sair" exact component={() => <Logout />} />
        <Route path="/gu-login/:token" exact component={() => <GuLogin />} />
        {tokenValido && Object.keys(permissoes).length > 0 && (
          <Base
            history={history}
            conteudo={
              <div style={{ height: "100%" }}>                
                {validarPermissaoAcessoTela('oportunidades') && (
                  <>
                    <Route path="/" exact component={() => <Oportunidades />} />
                    <Route path="/oportunidades" exact component={() => <Oportunidades />} />
                  </>
                )}

                {validarPermissaoAcessoTela('oportunidades') ? (
                  <Route path="/relatorio/geral" exact component={() => <Relatorios />} />
                ) : <Route path="/relatorio/*"component={()=><AlertSemPemissaoPagina/>}/>}

                {validarPermissaoAcessoTela('oportunidades') ? (
                  <Route path="/relatorio/diversos" exact component={() => <RelatoriosDiversos />} />
                ) : <Route path="/relatorio/*"component={()=><AlertSemPemissaoPagina/>}/>}

                {validarPermissaoAcessoTela('detalhes_oportunidade') ? (
                    <Route path="/oportunidade/:id" exact component={() => <DetalheOportunidade />}/>
                ) : <Route path="/oportunidade/*"component={()=><AlertSemPemissaoPagina/>}/>}

                {validarPermissaoAcessoTela('campanhas') ? (
                  <Route path="/campanha" exact component={() => <Campanhas />} />
                ) : <Route path="/campanha"component={()=><AlertSemPemissaoPagina/>}/>}

                {validarPermissaoAcessoTela('dashboards') && (
                  <>
                    <Route path="/dashboard" exact component={() => <NewDashboard />} />
                    <Route path="/dashboards" exact component={() => <Dashboards />} />
                  </>
                )}

                {validarPermissaoAcessoTela('leads_nao_distribuidos') ? (
                  <>
                    <Route
                      path="/leads/represados"
                      exact
                      component={() => <LeadsNaoDistribuidos />}
                    />
                  </>
                ) : <Route path="/leads/represados"component={()=><AlertSemPemissaoPagina/>}/>}

                {validarPermissaoAcessoTela('historico_distribuicao') ? (
                  <>
                    <Route
                      path="/leads/historico-distribuicao"
                      exact
                      component={() => 
                        <HistoricoDistribuicao />
                    }
                    />
                  </>
                ) : <Route path="/leads/historico"component={()=><AlertSemPemissaoPagina/>}/>}

                {validarPermissaoAcessoTela('central_recepcao_leads') ? (
                  <>
                    <Route
                      path="/leads/central-recepcao-leads"
                      exact
                      component={() => <CentralRecepcaoLeads />}
                    />
                  </>
                ) : <Route path="/leads/central-recepcao-leads"component={()=><AlertSemPemissaoPagina/>}/>}

                {validarPermissaoAcessoTela('integracoes') ? (
                  <Route path="/integracoes" exact component={() => <Integracoes />} />
                ) : <Route path="/integracoes"component={()=><AlertSemPemissaoPagina/>}/>}

                {validarPermissaoAcessoTela('importacao') ? (
                  <Route path="/importacao" exact component={() => <Importacao />} />
                ) : <Route path="/importacao"component={()=><AlertSemPemissaoPagina/>}/>}

                {validarPermissaoAcessoTela('filas-atendimento') ? (
                  <Route
                    path="/filas-atendimento"
                    exact
                    render={(props) => <FilasAtendimento {...props} />}
                  />
                ) : <Route path="/filas-atendimento"component={()=><AlertSemPemissaoPagina/>}/>}

                {validarPermissaoAcessoTela('oferta_ativa') ? (
                  <>
                    <Route path="/oferta-ativa" exact component={() => <OfertaAtiva />} />
                    <Route
                      path="/oferta-ativa/:id"
                      exact
                      component={() => <OfertaAtivaAcessar />}
                    />
                    <Route
                      path="/oferta-ativa/:id/:agendados"
                      exact
                      component={() => <OfertaAtivaAcessar />}
                    />
                  </>
                ) : <Route path="/oferta-ativa"component={()=><AlertSemPemissaoPagina/>}/>}

                {validarPermissaoAcessoTela('agendamento_visita') ? (
                  <>
                    <Route
                      path="/agenda-do-usuario"
                      exact
                      component={() => <AgendamentoVisita />}
                    />
                  </>
                ) : <Route path="/agenda-do-usuario"component={()=><AlertSemPemissaoPagina/>}/>}

                {validarPermissaoAcessoTela('agendamento_visita') ? (
                  <>
                    <Route
                      path="/solicitacoes"
                      exact
                      component={() => <SolicitacoesClonagem />}
                    />
                  </>
                ) : <Route path="/solicitacoes"component={()=><AlertSemPemissaoPagina/>}/>}

                {validarPermissaoAcessoTela('empreendimentos') ? (
                  <>
                    <Route
                      path="/portal-do-corretor"
                      exact
                      component={() => <PortalCorretor />}
                    />
                  </>
                ) : <Route path="/portal-do-corretor"component={()=><AlertSemPemissaoPagina/>}/>}

                {validarPermissaoAcessoTela('perfil_empreendimento') ? (
                  <Route
                    path="/perfil-empreendimento/:id"
                    exact
                    render={(props) => (
                      <PerfilEmpreendimento {...props}/>
                    )}
                  />
                ) : <Route path="/perfil-empreendimento/*"component={()=><AlertSemPemissaoPagina/>}/>}

                <Route path="/investimento-midias" exact component={ () => validarPermissaoAcessoTela('investimento_midias') ? <InvestimentoMidias/> : <AlertSemPemissaoPagina/> }/>

                {validarPermissaoAcessoTela('configuracoes') ? (
                  <>
                  <Route path="/configuracoes/funil-de-vendas" exact component={() => <FunilDeVendas />} />
                  <Route path="/configuracoes/etapas-e-status" exact component={() => <GestaoDeEtapasDeStatus />}/>
                    <Route path="/configuracoes/midia" exact component={() => <Midia />} />
                    <Route
                      path="/configuracoes/motivos_desistencia"
                      exact
                      component={() => <MotivosDesistencia />}
                    />
                    <Route
                      path="/configuracoes/temperatura"
                      exact
                      component={() => <Temperatura />}
                    />
                    <Route
                      path="/configuracoes/tipo/pendencias"
                      exact
                      component={() => <TipoPendencia />}
                    />
                    <Route
                      path="/configuracoes/tipo/historico_lead"
                      exact
                      component={() => <TipoHistoricoLead />}
                    />
                    <Route
                      path="/configuracoes/origem"
                      exact
                      component={() => <Origem />}
                    />
                    <Route
                      path="/configuracoes/mapeamento/midias"
                      exact
                      component={() => <MapeamentoMidias />}
                    />
                    <Route
                      path="/configuracoes/mapeamento/origem"
                      exact
                      component={() => <MapeamentoOrigem />}
                    />
                    <Route
                      path="/configuracoes/mapeamento/campanhas"
                      exact
                      component={() => <MapeamentoCampanhas />}
                    />
                    <Route
                      path="/configuracoes/mapeamento/empreendimentos"
                      exact
                      component={() => <MapeamentoEmpreendimentos />}
                    />
                    <Route
                      path="/configuracoes/mapeamento/imovelweb"
                      exact
                      component={() => <MapeamentoImovelWeb />}
                    />
                     <Route
                      path="/configuracoes/mapeamento/email"
                      exact
                      component={() => <MapeamentoEmail />}
                    />
                    <Route
                      path="/configuracoes/gestao/"
                      exact
                      component={() => <ConfiguracoesGestao />}
                    /> 
                    <Route
                      path="/configuracoes/locais/"
                      exact
                      component={() => <LocaisAtendimentos />}
                    />
                    <Route
                      path="/configuracoes/automacoes"
                      exact
                      component={() => <Automacoes />}
                    />
                    <Route
                      path="/configuracoes/questionarios"
                      exact
                      component={() => <Questionario />}
                    />
                    <Route path="/configuracoes/rotinas/" exact component={() => <RotinasDoSistema/>}/>
                  </>
                ) : <Route path="/configuracoes/*"component={()=><AlertSemPemissaoPagina/>}/>}

                {validarPermissaoAcessoTela('visitas_recepcionista') ? (
                  <>
                    <Route
                      path="/visitas"
                      exact
                      component={() => <VisitasRecepcionista />}
                    />
                  </>
                ) : <Route path="/visitas"component={()=><AlertSemPemissaoPagina/>}/>}

                {validarPermissaoAcessoTela('perfis_usuarios') ? (
                  <>
                    <Route
                      path="/perfis-usuarios"
                      exact
                      component={() => <PerfisUsuarios />}
                    />
                  </>
                ) : <Route path="/perfis-usuarios"component={()=><AlertSemPemissaoPagina/>}/>}

                {validarPermissaoAcessoTela('campos_qualificacao') ? (
                  <>
                    <Route
                      path="/campos-qualificacao"
                      exact
                      component={() => <CamposQualificacao />}
                    />
                  </>
                ) : <Route path="/campos-qualificacao"component={()=><AlertSemPemissaoPagina/>}/>}

                {validarPermissaoAcessoTela('regua_nutricao') ? (
                  <ReguaNutricaoProvider>
                    <Route path="/regua-nutricao" exact component={() => <ReguaNutricao />}/>
                  </ReguaNutricaoProvider>
                ) : <Route path="/regua-nutricao"component={()=><AlertSemPemissaoPagina/>}/>}
                <Route
                  path="/perfil"
                  exact
                  component={() => <PerfilUsuario />}
                />
                <Route
                  path="/atualizacoes"
                  exact
                  component={() => <Atualizacoes />}
                />
                <Route
                  path="/tempo-online"
                  exact
                  component={() => <TempoOnline />}
               />

              <ContextAcompanhamentoHistoricoProvider>

                    <Route path="/acompanhamentos-historicos"
                    exact
                    component={() => <AcompanhamentoHistorico />}
                    />

              </ContextAcompanhamentoHistoricoProvider>  

              <ContextAcompanhamentoReguaNutricaoProvider>
                  <Route path="/acompanhamentos-regua-nutricao" 
                  exact 
                  component={() => <AcompanhamentoReguaNutricao />}
                  />
              </ContextAcompanhamentoReguaNutricaoProvider>  

            </div>
          }
          ></Base>
        ) || <DommusLoading/>}
      </Suspense>
  </Switch>
  );
}
