import crm from './crm';
import guService from "./GuService";

const ofertaAtiva = {
    lista: () => {
        return crm.get(`/oferta-ativa`);
    },

    buscarOferta: id_oferta_ativa => {
        return crm.get(`/oferta-ativa/${id_oferta_ativa}`)
    },

    store: (data) => {
        return crm.post(`/oferta-ativa`, data);
    },

    update: (data, id) => {
        return crm.put(`/oferta-ativa/${id}`, data);
    },

    delete: (id_oferta) => {
        return crm.delete(`/oferta-ativa/${id_oferta}`);
    },

    agendar: (formData) => {
        return crm.post('/oferta-ativa/agendar-lead', formData);
    },

    buscarEmpreendimentos: () => {
        return crm.get(`oferta-ativa/buscar-empreendimentos`);
    },

    buscaLeadAleatorio: id_oferta_ativa => {
        return crm.get(`oferta-ativa/lead-aleatorio/${id_oferta_ativa}`);
    },

    atualizarConfiguracoes: () => {
        return crm.get(`/oferta-ativa/configuracoes`)
    },

    descartar: (id_oferta_ativa, id_lead, dataForm) => {
        return crm.put(`oferta-ativa/descartar/${id_oferta_ativa}/${id_lead}`, dataForm)
    },

    resgatar: (dados) => {
        dados.tipo_usuario = guService.getLocalState('tipoUsuario');
        return crm.put(`oferta-ativa/resgatar`, dados)
    },

    importarLeads: (formData) => {
        return crm.post('oferta-ativa/adiciona-leads', formData);
    },

    getLeads: (id_oferta_ativa) => {
        return crm.get('oferta-ativa/leads/'+id_oferta_ativa);
    },

    getLeadsAgendados: (id_oferta_ativa) => {
        return crm.get('oferta-ativa/leads-agendados/'+id_oferta_ativa);
    },

    filtrar: {
        nao_distribuido: (formData) => {
            return crm.post('/oferta-ativa/filtrar/nao-distribudos', formData);
        },

        oportunidades: (formData) => {
            return crm.post('/oferta-ativa/filtrar/oportunidades', formData);
        },
    },

    midia: {
        buscar: () => {
            return crm.get(`/midia`);
        },
    },

    campanha: {
        buscar: () => {
            return crm.get(`/campanha`);
        }
    },

    permissao: {
        buscaEquipes: (id_oferta_ativa) => {
            return crm.get(`oferta-ativa/permissao/busca-equipes/${id_oferta_ativa}`);
        },

        buscaListaPermissoes: id_oferta_ativa => {
            return crm.get(`/oferta-ativa/permissao/lista-usuarios/${id_oferta_ativa}`);
        },

        store: formData => {
            return crm.post('oferta-ativa/permissao', formData);
        }
    },

    filtrarLeadsOA: (id_oferta_ativa, filtro) => {
        return crm.get('oferta-ativa/leads/' + id_oferta_ativa, { params: filtro });
    }
};

export default ofertaAtiva;
