import React, { useContext, useEffect, useMemo } from "react";
import "./index.css";
import { PerguntaQuestionarioType } from "../../../../../contexts/QuestionarioContext/types";
import { TipoRespostaEnum } from "../../../../../contexts/PerguntaContext/types";
import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import {
  celularMask,
  cepMask,
  cpfMask,
  realMoeda,
} from "../../../../../components/InputTextMask";
import Select from "react-select";
import { MultiSelect, Option } from "react-multi-select-component";
import { CamposDinamicosContext } from "../../../../../contexts/CamposDinamicosContext/CamposDinamicosContext";
import { EmpreendimentoContext } from "../../../../../components/GlobalContext/globalContext";
import { TipoCampoDinamicoAPI } from "../../../Cadastro/types.d";
import { QuestionarioContext } from "../../../../../contexts/QuestionarioContext";

interface PerguntaOportunidadeItemProps {
  pergunta: PerguntaQuestionarioType;
  index: number;
}

export function PerguntaOportunidadeItem({
  pergunta, index
}: PerguntaOportunidadeItemProps) {
  const { register, setValue, getValues, control } = useFormContext();
  const { listaComoConheceu, listaLocalidades, listaTipologia } = useContext(
    CamposDinamicosContext
  );
  const { empreendimentos } = useContext(EmpreendimentoContext);
  const { listaRespostas, resolverResposta } = useContext(QuestionarioContext);

  const configs = useMemo(() => {
    return {
      selectSomeItems: "Selecione",
      allItemsAreSelected: "Todos itens selecionados",
      selectAll: "Selecionar todos",
      search: "Pesquisar",
    };
  }, []);

  const nameCampo = useMemo(() => {
    return pergunta.id_pergunta.toString();
  }, [pergunta]);

  const resposta = useMemo(()=>{
    return resolverResposta(pergunta.pergunta, pergunta.id_questionario);
  },[listaRespostas, pergunta]);


  useEffect(() => {
    let valorResposta;
    try {
      valorResposta = JSON.parse(resposta.resposta);
    } catch (error) {
      valorResposta = resposta.resposta;
    }
    switch (pergunta.pergunta.id_tipo_resposta_pergunta) {
      case TipoRespostaEnum.MULTIPLA_SELECAO:
      case TipoRespostaEnum.MULTIPLA_SELECAO_CAMPOS_DINAMICOS:
        valorResposta = valorResposta ?? [];
        break;
      case TipoRespostaEnum.CEP:
        valorResposta = valorResposta ? cepMask(valorResposta.toString()) : "";
        break;
      case TipoRespostaEnum.CPF:
        valorResposta = valorResposta ? cpfMask(valorResposta.toString()) : "";
        break;
      case TipoRespostaEnum.TELEFONE:
        valorResposta = valorResposta ? celularMask(valorResposta.toString()) : "";
        break;    
      case TipoRespostaEnum.MOEDA:
        valorResposta = valorResposta ? `R$ ${realMoeda(valorResposta.toString())}` : "";
        break;          
      default:        
        break;
    }
    
    setValue(pergunta.id_pergunta.toString(), valorResposta);
  }, [resposta, pergunta]);

  const createOptionsCamposDinamicos = (tipo: number) => {
    switch (tipo) {
      case TipoCampoDinamicoAPI.COMO_CONHECEU:
        return listaComoConheceu.map((comoConheceu) => {
          return {
            label: comoConheceu.descricao,
            value: comoConheceu.id_como_conheceu,
          };
        });
      case TipoCampoDinamicoAPI.LOCALIZACAO:
        return listaLocalidades.map((localidade, index) => {
          return {
            label: localidade,
            value: localidade,
          };
        });
      case TipoCampoDinamicoAPI.TIPOLOGIA:
        return listaTipologia.map((tipologia, index) => {
          return {
            label: tipologia.descricao,
            value: tipologia.id,
          };
        });
      case TipoCampoDinamicoAPI.EMPREENDIMENTO:
        return empreendimentos.map((empreendimento, index) => {
          return {
            label: empreendimento.nome,
            value: empreendimento.id,
          };
        });
      default:
        return [];
    }
  };

  const resolverCampo = () => {
    var options = [];
    switch (pergunta.pergunta.id_tipo_resposta_pergunta) {
      case TipoRespostaEnum.CEP:
        return (
          <Form.Control
            type="text"
            ref={register()}
            name={nameCampo}
            placeholder="Digite o CEP..."
            onChange={(event) => {
              setValue(nameCampo, cepMask(event.target.value));
            }}
          />
        );
      case TipoRespostaEnum.CPF:
        return (
          <Form.Control
            type="text"
            ref={register()}
            name={nameCampo}            
            placeholder="Digite o CPF..."
            onChange={(event) => {
              setValue(nameCampo, cpfMask(event.target.value));
            }}
          />
        );
      case TipoRespostaEnum.TELEFONE:
        return (
          <Form.Control
            type="text"
            ref={register()}
            name={nameCampo}
            placeholder="Digite o telefone..."
            onChange={(event) => {
              setValue(nameCampo, celularMask(event.target.value));
            }}
          />
        );
      case TipoRespostaEnum.TEXTO:
        return (
          <Form.Control
            type="text"
            ref={register()}
            name={nameCampo}
            placeholder="Digite ..."
          />
        );
      case TipoRespostaEnum.DATA:
        return <Form.Control type="date" ref={register()} name={nameCampo} />;
      case TipoRespostaEnum.MOEDA:
        return (
          <Form.Control
            type="text"
            ref={register()}
            name={nameCampo}
            placeholder="R$ 0,00"
            onChange={(event) => {
              setValue(nameCampo, `R$ ${realMoeda(event.target.value)}`);
            }}
          />
        );
      case TipoRespostaEnum.SIMPLES_SELECAO:
      case TipoRespostaEnum.SIMPLES_SELECAO_CAMPOS_DINAMICOS:
        if (Array.isArray(pergunta.pergunta.options)) {
          options = pergunta.pergunta.options;
        } else {
          options = createOptionsCamposDinamicos(
            parseInt(pergunta.pergunta?.options || "0")
          );
        }
        return (
          <Controller
            control={control}
            name={nameCampo}
            defaultValue={[]}
            as={
              <Select
                placeholder="Selecione"
                name={nameCampo}
                options={(options as Option[]) || []}
                className="basic-multi-select"
              />
            }
          />
        );
      case TipoRespostaEnum.MULTIPLA_SELECAO:
      case TipoRespostaEnum.MULTIPLA_SELECAO_CAMPOS_DINAMICOS:
        if (Array.isArray(pergunta.pergunta.options)) {
          options = pergunta.pergunta.options;
        } else {
          options = createOptionsCamposDinamicos(
            parseInt(pergunta.pergunta?.options || "0")
          );
        }
        return (
          <Controller
            control={control}
            name={nameCampo}
            defaultValue={[]}
            as={
              <MultiSelect
                options={(options as Option[]) ?? []}
                labelledBy=""
                value={[]}
                overrideStrings={configs}
              />
            }
          />
        );
      case TipoRespostaEnum.CHECKBOX:
        return (
          <div className="resposta-checkbox-section">
            <Controller
              control={control}
              name={nameCampo}
              defaultValue={[]}
              key={nameCampo}
              as={
                <>
                  {pergunta.pergunta?.options?.map(
                    (item: any, index: number) => (
                      <div key={index}>
                        <input
                          ref={register()}
                          id={`${pergunta.id_questionario}-${nameCampo}-${index}`}
                          name={nameCampo}
                          value={item.value}
                          type="checkbox"
                        />
                        <label
                          style={{ cursor: "pointer", marginBottom: "0px" }}
                          htmlFor={`${pergunta.id_questionario}-${nameCampo}-${index}`}
                        >
                          {item.value}
                        </label>
                      </div>
                    )
                  )}
                </>
              }
            />
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="pergunta-opv-item">
      <div className="enunciado-section">
        <span style={{ fontWeight: "700", color: "#999" }}>
          {`${(index+1).toString().padStart(2, '0')}. `}
        </span>
        <span>{pergunta.pergunta.enunciado}</span>
      </div>
      {resolverCampo()}
    </div>
  );
}
