import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { GlobalContext } from "./components/GlobalContext/globalContext";
import { StatusOportunidadeProvider } from "./contexts/StatusOportunidadeContext/StatusOportunidadeContext";
import { SubstatusOportunidadeProvider } from "./contexts/SubstatusOportunidadeContext/SubstatusOportunidadeContext";
import { TiposDocumentosProvider } from "./contexts/TiposDocumentosContext/TiposDocumentosContext";
import { TiposPendenciasProvider } from "./contexts/tiposPendenciasContext/TiposPendenciasContext";
import { CamposDinamicosProvider } from "./contexts/CamposDinamicosContext/CamposDinamicosContext";
import { AutomacaoProvider } from "./contexts/AutomacaoContext";
import { FunilProvider } from "./contexts/FunilContext";
import { PerguntaProvider } from "./contexts/PerguntaContext";
import { QuestionarioProvider } from "./contexts/QuestionarioContext";
import { ContatoNotificacaoProvider } from "./contexts/ContatoNotificacaoContext/ContatoNotificacaoContext";
import { GrupoQualificacaoProvider } from "./contexts/GruposQualificacaoContext/GrupoQualificacaoContext";
import { PusherContextProvider } from "./contexts/PusherContext";
import { AtendimentoProvider } from "./contexts/AtendimentoContext";
import { TipoHistoricoProvider } from "./contexts/TipoHistoricoContext/TipoHistoricoContext";
import { DetalhesOportunidadeProvider } from "./contexts/DetalhesOportunidadeContext/DetalhesOportunidadeContext";
import { FiltroGlobalProvider } from "./contexts/FiltroGlobalContext";
import { MeiosComunicacaoProvider } from "./contexts/MeiosComunicacaoContext";
import { TagOportunidadeProvider } from "./contexts/TagOportunidadeContext";
import { UsuarioContextProvider } from "./contexts/UsuarioContext";
import { CanaisContextProvider } from "./contexts/CanaisContext";
import { MidiaInvestimentoContextProvider } from "./contexts/MidiaInvestimentoContext";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <GlobalContext>
        <UsuarioContextProvider>
            <PusherContextProvider>
                <TipoHistoricoProvider>
                    <StatusOportunidadeProvider>
                        <SubstatusOportunidadeProvider>
                            <TiposDocumentosProvider>
                                <TiposPendenciasProvider>
                                    <CamposDinamicosProvider>
                                        <AutomacaoProvider>
                                            <FunilProvider>
                                                <ContatoNotificacaoProvider>
                                                    <GrupoQualificacaoProvider>
                                                        <PerguntaProvider>
                                                            <QuestionarioProvider>
                                                                <DetalhesOportunidadeProvider>
                                                                    <FiltroGlobalProvider>
                                                                        <MeiosComunicacaoProvider>
                                                                            <CanaisContextProvider>
                                                                                <TagOportunidadeProvider>
                                                                                    <AtendimentoProvider>
                                                                                        <MidiaInvestimentoContextProvider>
                                                                                            <App />
                                                                                        </MidiaInvestimentoContextProvider>
                                                                                    </AtendimentoProvider>
                                                                                </TagOportunidadeProvider>
                                                                            </CanaisContextProvider>
                                                                        </MeiosComunicacaoProvider>
                                                                    </FiltroGlobalProvider>
                                                                </DetalhesOportunidadeProvider>
                                                            </QuestionarioProvider>
                                                        </PerguntaProvider>
                                                    </GrupoQualificacaoProvider>
                                                </ContatoNotificacaoProvider>
                                            </FunilProvider>
                                        </AutomacaoProvider>
                                    </CamposDinamicosProvider>
                                </TiposPendenciasProvider>
                            </TiposDocumentosProvider>
                        </SubstatusOportunidadeProvider>
                    </StatusOportunidadeProvider>
                </TipoHistoricoProvider>
            </PusherContextProvider>
        </UsuarioContextProvider>
    </GlobalContext>
);
