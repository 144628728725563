import React, { useContext, useMemo } from "react";
import "./index.css";
import { QuestionarioType } from "../../../../contexts/QuestionarioContext/types";
import { OportunidadeType } from "../../Cadastro/types";
import DommusToggle from "../../../../components/DommusToggle";
import { FormProvider, useForm } from "react-hook-form";
import { Alert, Button, Form, Row } from "react-bootstrap";
import { PerguntaOportunidadeItem } from "./PerguntaOportunidadeItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import { toastSucesso } from "../../../../helpers/DommusSwal";
import QuestionarioService from "../../../../services/QuestionarioService";
import { QuestionarioContext } from "../../../../contexts/QuestionarioContext";
import { TipoRespostaEnum } from "../../../../contexts/PerguntaContext/types";
import { apenasNumeros } from "../../../../components/InputTextMask";
import { isMobile, isTablet } from "react-device-detect";

interface QuestionarioOportunidadadeItemProps {
  questionario: QuestionarioType;
  oportunidade: OportunidadeType;
}

export function QuestionarioOportunidadadeItem({
  questionario,
  oportunidade,
}: QuestionarioOportunidadadeItemProps) {
  const { atualizarRespostas, ultimasAtualizacoesQuestionario, getUltimaAtualizacaoQuestionario  } = useContext(QuestionarioContext);
  const metodos = useForm();

  const ultimaAtualizacao = useMemo(()=>{
    return getUltimaAtualizacaoQuestionario(questionario.id_questionario);
  },[ultimasAtualizacoesQuestionario])

  const formatarResposta = (data: any) => {
    Object.keys(data).map((key) => {
      let pergunta = questionario.perguntas?.find(
        (pergunta) => pergunta.id_pergunta === parseInt(key)
      );
      switch (pergunta?.pergunta.id_tipo_resposta_pergunta) {
        case TipoRespostaEnum.CEP:
        case TipoRespostaEnum.CPF:
        case TipoRespostaEnum.TELEFONE:
        case TipoRespostaEnum.MOEDA:
          data[key] = apenasNumeros(data[key]);
          break;
        default:
          break;
      }
    });
    return data;
  };

  const handleOnSubmit = (data: any) => {
    let respostasFormatadas = formatarResposta(data);
    trackPromise(
      QuestionarioService.storeRespostasQuestionario(
        oportunidade.id_oportunidade,
        questionario.id_questionario,
        respostasFormatadas
      )
    )
      .then((response: any) => {
        atualizarRespostas(response.data);
        toastSucesso("Respostas salvas com sucesso");
      })
      .catch((error) => {
        Swal.fire({
          titleText: "Ooops...",
          text:
            "Ocorreu um erro ao tentar salvar as respostas: " +
              error?.response?.data ?? error,
          icon: "error",
        });
      });
  };

  const icones = useMemo(()=>{
    return(
      isMobile && !isTablet ?
      <div className="atualizacao-section-mobile">
        <div>
          <span>Ultima Atualização: </span>
          <span>{ultimaAtualizacao?.usuario}</span>
        </div>
        <span>{ultimaAtualizacao?.data}</span>
      </div>
      :
      <div className="atualizacao-section">
        <span>Ultima Atualização: </span>
        <span>{ultimaAtualizacao?.usuario}</span>
        <span>-</span>
        <span>{ultimaAtualizacao?.data}</span>
      </div>
    );
  },[ultimaAtualizacao])



  return (
    <FormProvider {...metodos}>
      <DommusToggle title={questionario.descricao} icones={(isMobile && !isTablet) ? <></> : icones}>
        <Form
          onSubmit={metodos.handleSubmit(handleOnSubmit)}
          className="form-perguntas-opv"
        >
          {(isMobile && !isTablet) &&
            icones
          }
          {questionario.perguntas?.length ? (
            <>
              {questionario.perguntas?.map((pergunta, index) => {
                return (
                  <PerguntaOportunidadeItem key={index} index={index} pergunta={pergunta} />
                );
              })}
              <Row className="justify-content-end mr-1">
                <Button variant="success" type="submit">
                  <FontAwesomeIcon icon={faSave} /> Salvar
                </Button>
              </Row>
            </>
          ) : (
            <Alert variant="warning">
              Nenhuma pergunta vinculada nesse questionário
            </Alert>
          )}
        </Form>
      </DommusToggle>
    </FormProvider>
  );
}
