import React, { useContext, useEffect, useMemo } from "react";
import { OportunidadeType } from "../Cadastro/types";
import { QuestionarioContext } from "../../../contexts/QuestionarioContext";
import { Alert } from "react-bootstrap";
import { QuestionarioOportunidadadeItem } from "./QuestionarioOportunidadeItem";
import { CamposDinamicosContext } from "../../../contexts/CamposDinamicosContext/CamposDinamicosContext";

interface QuestionariosOportunidadeProps {
  oportunidade: OportunidadeType;
}

export function QuestionariosOportunidade({
  oportunidade,
}: QuestionariosOportunidadeProps) {
  const { listaQuestionarios, recarregarRespostas } = useContext(QuestionarioContext);
  const {recarregarComoConheceu, recarregarLocalidades, recarregarTipologias} = useContext(CamposDinamicosContext);

  useEffect(()=>{
    recarregarComoConheceu();
    recarregarLocalidades();
    recarregarTipologias();
    recarregarRespostas(oportunidade.id_oportunidade);
  },[])

  const questionariosOpv = useMemo(() => {
    return listaQuestionarios.filter((questionario) => {
      let empreendimentos = questionario?.empreendimentos_vinculados?.map(
        (empreendimento: any) => parseInt(empreendimento.value)
      );
      return empreendimentos?.includes(oportunidade.id_empreendimento);
    });
  }, [listaQuestionarios]);

  return questionariosOpv.length ? (
    questionariosOpv.map((questionario, index) => {
      return (
        <QuestionarioOportunidadadeItem
          key={index}
          oportunidade={oportunidade}
          questionario={questionario}
        />
      );
    })
  ) : (
    <Alert variant="warning">
      Não possui nenhum questionário vinculado a esse empreendimento!
    </Alert>
  );
}
